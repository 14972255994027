.view-map {

}

#map,
#placeholder {
  position: absolute; z-index: 1;
  top: 0; left: 0; right: 0; bottom: 0;
  margin: 0;
  padding: 0;
  background: #000;
}
#placeholder {
  z-index: 0;
}
#placeholder.share {
  width: 1200px; height: 630px;
  left: auto; top: auto;
}
.hover {
  cursor: pointer;
}
#map::after {
  position: absolute; z-index: 1;
  top: 0; left: 0; right: 0; bottom: 16px;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  content: "";
}
  #map canvas {
    filter: grayscale(1) brightness(0.85) contrast(1.5);
    transform: translate3d(0, 0, 0);
    transition: filter 500ms ease-out;
  }

.errors {
  position: absolute; z-index: 5;
  top: 0; left: 0; right: 0; bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 500ms ease-out;
  text-align: center;
}
.view.active .errors.active {
  opacity: 1;
  pointer-events: auto;
}
  .errors div {
    position: relative;
    top: 25vh;
    padding: 4rem;
  }
  .errors h2 {
    font-family: "bebas-regular", Arial, sans-serif;
    font-size: 8vw;
    letter-spacing: -0.02em;
    line-height: 0.85;
    text-transform: uppercase;
  }
  .errors p {
    max-width: 50vw;
    margin: 0 auto;
    padding: 4rem 0;
    font-size: 1.5vw;
  }
  .errors .button-close {
    top: 2rem; right: 0; left: 2rem;
    font-size: 1.5rem;
    font-weight: 100;
    line-height: 2.75;
    text-decoration: none;
    text-indent: 3rem;
    text-transform: uppercase;
    background-position: 0 50%;
    background-size: 2rem auto;
    overflow: visible;
  }
  .errors .button.button-light {
    color: #000;
    font-size: 1vw;
  }
  .errors .button.button-light:hover {
    color: #fff;
    background: #fc0a1d;
  }
@media screen and (max-width: 900px) {
  .errors p {
    max-width: 70vw;
    font-size: 3vw;
  }
  .errors .button.button-light {
    font-size: 3vw;
  }
}

.input-wrapper {
  position: absolute; z-index: 5;
  top: 4rem; left: 0;
  width: 30rem;
  margin: 0 0 0 -15rem;
  color: #000;
  transition:
    transform 500ms ease-out,
    width 500ms ease-out,
    margin 500ms ease-out;
  transform: translate3d(50vw, 47%, 0);
  transform: translate3d(50vw, calc(50vh - 12rem), 0);
}
.input-wrapper.active {
  width: 20vw;
  margin: 0 0 0 -10vw;
  transform: translate3d(calc(100vw / 6), 0, 0);
}
  .input-wrapper input {
    display: block;
    box-sizing: border-box;
    width: 100%; height: 5rem;
    color: #fff;
    font-size: 1.8rem;
    padding: 0 5rem 0 1rem;
    border: 0;
    background: rgba(0, 0, 0, 0.6);
    background-clip: content-box !important;
    box-shadow: 0 0 0 60px rgba(0, 0, 0, 0.6) inset !important;
    appearance: none;
    transition: background-color 300ms ease-out;
  }
  .input-wrapper input:focus {
    background: rgba(10, 10, 10, 0.6);
    box-shadow: 0 0 0 60px rgba(10, 10, 10, 0.6) inset !important;
  }
    .input-wrapper input::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.8);
    }
    .input-wrapper input::-moz-placeholder {
      color: rgba(255, 255, 255, 0.8);
      opacity: 1;
    }
    .input-wrapper input:-moz-placeholder {
      color: rgba(255, 255, 255, 0.8);
    }
    .input-wrapper input:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.8);
      opacity: 1;
    }
  .input-wrapper input:-webkit-autofill,
  .input-wrapper input:-webkit-autofill:hover,
  .input-wrapper input:-webkit-autofill:focus,
  .input-wrapper input:-webkit-autofill:active {
    background: rgba(0,0,0,.6);
  }

@media screen and (max-width: 900px) {
  .input-wrapper {
    width: 72vw;
    top: -6rem;
    margin-left: -30vw;
  }
  .input-wrapper.active {
    width: 72vw;
    margin-left: -30vw;
    transform: translate3d(50vw, 8rem, 0);
  }
}

.input-results {
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  list-style: none;
  background: rgba(0, 0, 0, 0.7);
}
  .input-results li {
    padding: 1rem 2rem;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0);
    cursor: pointer;
    transition: background-color 300ms ease-out;
  }
  .input-results li:hover {
    background: rgba(255, 255, 255, 0.05);
  }
  .input-results li:first-child {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  .input-geolocate {

  }
    .input-geolocate img {
      display: inline-block;
      height: 1.75rem;
      position: relative;
      top: 0.4rem; left: -0.5rem;
      padding-right: 1rem;
    }

.layers {
  position: absolute; z-index: 2;
  top: 0; right: 0; bottom: 0; left: 0;
  overflow: hidden;
  pointer-events: none;
}
  .layers .text-wrapper {
    width: 66.6666%;
    width: calc(200% / 3);
    height: 100%;
    position: absolute; z-index: 6;
    top: 0; left: 0;
    opacity: 0;
    transform: translate3d(0, 0, 0);
    transition: opacity 250ms ease-out;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5) 25%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .layers .text-wrapper.active {
    opacity: 1;
    transition: opacity 1s ease-out;
  }
  .layers .text {
    width: 50%; height: 6em;
    position: absolute; z-index: 0;
    top: 50%; left: 0;
    margin: -3em 0 0;
    color: #fff;
    font-family: "bebas-regular", Arial, sans-serif;
    font-size: 5vw;
    line-height: 0.9;
    text-align: center;
  }
    .layers .text .prefix {
      width: 4.6em;
      margin: 0 auto;
    }
    .layers .text .value {
      font-size: 3.5em;
      font-weight: 400;
      line-height: 0.7;
    }
    .layers .text .suffix {
      font-size: 1.6em;
      line-height: 1.1;
    }
@media screen and (max-width: 900px) {
  .layers .text-wrapper {
    width: 100%; height: 50vh;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5) 25%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .layers .text {
    width: 6em;
    top: 9rem; left: 50%;
    margin: 0 0 0 -3em;
    font-size: 6vh;
  }
}

.markers {
  position: absolute; z-index: 1;
  top: 0; right: 0; bottom: 0; left: 0;
}
  .marker {
    position: absolute; z-index: 1;
    top: 0; left: 0;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
    transform: translate3d(0, 0, 0);
    transform-origin: 50% 100%;
    transition: opacity 250ms ease-out 0s;
  }
  .marker.active {
    opacity: 1;
    transition: opacity 1s ease-out 1s;
  }
  .marker-event.dot {
    background: transparent;
  }
    .marker-event.dot::after {
      width: 1rem; height: 1rem;
      position: absolute; z-index: 1;
      top: 100%; left: 50%;
      margin: -0.5rem 0 0 -0.5rem;
      border-radius: 50%;
      background: #fc0a1d;
      content: "";
    }
  .marker-active,
  .marker-event {
    width: 8rem; height: 12rem;
    z-index: 2;
    margin: -12rem 0 0 -4rem;
    background-image: url("https://assets.dodgethebullet.ca/images/icon-marker-event.png");
  }
  .marker-user {
    width: 12rem; height: 12rem;
    z-index: 3;
    margin: -6rem 0 0 -6rem;
    background-image: url("https://assets.dodgethebullet.ca/images/icon-marker-user.png");
    background-size: auto 120%;
  }
  .view.active .marker-event {
    z-index: 1;
    cursor: pointer;
    opacity: 1;
  }

@media screen and (max-width: 900px) {
  .marker-active,
  .marker-event {
    width: 4rem; height: 6rem;
    margin: -6rem 0 0 -2rem;
  }
  .marker-user {
    width: 9rem; height: 9rem;
    margin: -4.5rem 0 0 -4.5rem;
  }
}

.panel {
  box-sizing: border-box;
  width: 33vw;
  position: absolute; z-index: 6;
  top: 0; bottom: 0; right: 0;
  color: #fff;
  background: #fc0a1de6;
  opacity: 0;
  pointer-events: none;
  transform: translate3d(100%, 0, 0);
  transition:
    opacity 500ms ease-out,
    width 500ms ease-out,
    transform 500ms ease-out;
}
.panel.active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.panel.closed {
  width: 5rem;
  overflow: hidden;
}
  .panel-content {
    box-sizing: border-box;
    width: 33vw; height: 35vw;
    position: absolute; z-index: 1;
    top: 50%; left: 0;
    margin-top: -17.5vw;
    padding: 2vw;
    transition: opacity 500ms ease-out;
  }
  .panel-content {
    opacity: 1;
  }
  .panel.closed .panel-content {
    opacity: 0;
    pointer-events: none;
  }
  .panel h1 {
    font-size: 5vw;
    line-height: 0.9;
    text-transform: uppercase;
    padding: 0 4vw 2vw 0;
  }
  .panel p {
    padding: 2vw 10vw 3.5vw 0;
    font-size: 1.1vw;
  }
  .panel .button {
    display: inline-block;
    width: 10vw; height: 2.8vw;
    margin: 0 0 2vh 1vw;
    padding: 0;
    text-align: center;
    font-size: 1vw;
    line-height: 2.8vw;
    vertical-align: top;
  }
  .view.active .panel .button {
    opacity: 1;
    pointer-events: auto;
  }
  .panel .button-open {
    display: block;
    width: auto; height: auto;
    position: absolute; z-index: 1;
    top: 0; right: 0; bottom: 0; left: 0;
    text-indent: -1234rem;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transition: opacity 500ms ease-out;
  }
  .panel .button-open::after {
    display: block;
    width: 3rem; height: 4rem;
    position: absolute; z-index: 1;
    top: 2vh; left: 1rem;
    font-size: 5rem;
    line-height: 3rem;
    text-align: center;
    text-decoration: none;
    text-indent: 0;
    background: url("https://assets.dodgethebullet.ca/images/icon-arrow.png") no-repeat 50%;
    background-size: contain;
    content: "";
  }
  .view.active .panel.closed .button-open {
    opacity: 1;
    pointer-events: auto;
  }
  .panel .date {
    padding: 0.75vw 0 0.25vw;
    font-size: 1.25vw;
  }
    .panel .date b {
      display: block;
      font-style: italic;
      font-weight: normal;
    }
    .panel .date span {
      font-weight: 1000;
    }

@media screen and (min-width: 901px) {
  .panel .date b {
    display: block;
    font-weight: normal;
    font-size: 1vw;
  }
    .panel .date span {
      font-weight: 1000;
    }
}

@media screen and (max-width: 900px) {
  .panel {
    width: auto; height: 53vw;
    top: auto; left: 0;
    text-align: center;
    transform: translate3d(0, 100%, 0);
    transition:
      opacity 500ms ease-out,
      height 500ms ease-out,
      transform 500ms ease-out;
  }
  .panel.active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  .panel.closed {
    width: 100%; height: 4rem;
  }
  .panel-content {
    width: 100%; height: auto;
    top: 0;
    margin: 0;
    padding: 0 4vw 2vw;
  }
  .panel h1 {
    font-size: 10vw;
    padding: 2vw 0 0;
  }
  .panel p {
    padding: 2vw 6vw 2vw 6vw;
    font-size: 4vw;
    line-height: 1.2;
  }
  .panel .date {
    padding: 1vw 0 0;
    font-size: 4vw;
    white-space: nowrap;
  }
    .panel .date b {
      display: inline-block;
      padding-right: 0.5vw;
    }
  .panel .button {
    display: inline-block;
    width: 33vw; height: 12vw;
    margin: 0 0 0 2vw;
    font-size: 4vw;
    line-height: 12vw;
  }
  .panel .button-open::after {
    width: 20%; height: 0.8rem;
    top: 1.6rem; left: 50%;
    margin-left: -10%;
    background: white;
    border-radius: 0.5rem;
    content: "";
  }
}

@media screen and (max-width: 900px) and (min-width: 480px) {
  .panel {
    height: 26vw;
  }
  .panel-content {
    padding: 0 3vw 1vw;
  }
  .panel h1 {
    font-size: 5vw;
  }
  .panel p {
    font-size: 1.5vw;
    max-width: 50vw;
    margin: 0 auto;
  }
  .panel .button {
    width: 22vw; height: 6vw;
    margin: 0 0 0 1vw;
    font-size: 2vw;
    line-height: 6vw;
  }
  .panel .date {
    font-size: 1.5vw;
  }
}

@media screen and (max-width: 600px) and (min-width: 480px) {
  .panel {
    height: 31vw;
  }
  .panel h1 {
    font-size: 6vw;
  }
  .panel p {
    font-size: 2vw;
    max-width: 60vw;
  }
  .panel .button {
    width: 25vw; height: 8vw;
    font-size: 3vw;
    line-height: 8vw;
  }
  .panel .date {
    font-size: 2vw;
  }
}

.button-search,
.button-geolocate,
.button-clear,
.button-close {
  display: block;
  position: absolute; z-index: 1;
  top: 0.5rem;
  width: 4rem; height: 4rem;
  padding: 0;
  border: 0;
  background: transparent no-repeat 50%;
  text-indent: -123rem;
  overflow: hidden;
  cursor: pointer;
  appearance: none;
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  transition: transform 300ms ease-out;
}
.button-search {
  left: -5rem;
  background-image: url("https://assets.dodgethebullet.ca/images/icon-search.png");
  background-size: 2.5rem auto;
}
.button-geolocate {
  right: -5rem;
  background-image: url("https://assets.dodgethebullet.ca/images/icon-geolocate.png");
  background-size: contain;
  opacity: 0;
  pointer-events: none;
  transition: transform 150ms ease-out, opacity 150ms ease-out;
}
.button-clear {
  height: 5rem;
  top: 0; right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url("https://assets.dodgethebullet.ca/images/icon-clear.png");
  background-size: 1rem auto;
  opacity: 0;
  pointer-events: none;
  transition: background-color 150ms ease-out, opacity 150ms ease-out;
}
.button-close {
  top: 2vh; left: 2vw;
  background-image: url("https://assets.dodgethebullet.ca/images/icon-close.png");
  background-position: 0 50%;
  background-size: 1.25vw auto;
}
.view.active .button-close,
.view.active .button-geolocate.active,
.view.active .button-clear.active {
  opacity: 1;
  pointer-events: auto;
}
.view.active .panel.closed .button-close {
  opacity: 0;
  pointer-events: none;
}
.button-search:hover,
.button-geolocate:hover,
.button-close:hover {
  transform: translate3d(0, 0, 0) scale3d(1.1, 1.1, 1.1);
}
.button-clear:hover {
  background-color: rgba(40, 40, 40, 0.75);
}

.button-share {
  display: inline-block;
  width: 2.8vw; height: 2.8vw;
  position: relative;
  margin: 0 1vw 0 0;
}
.view.active .button-share {
  pointer-events: auto;
}
.button-share span {
  display: inline-block;
  width: 100%; height: 100%;
  border-radius: 50%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 1.75vw auto;
  background-color: #fff;
  transition: filter 100ms ease-out, background-color 100ms ease-out;
}
.button-share:hover span {
  background-color: #000;
  filter: saturate(0) brightness(4);
  pointer-events: none;
}
.button-share[data-network="facebook"] span {
  background-image: url("https://assets.dodgethebullet.ca/images/icon-share-facebook.png");
}
.button-share[data-network="twitter"] span {
  background-image: url("https://assets.dodgethebullet.ca/images/icon-share-twitter.png");
}
.button-share[data-network="link"] span {
  background-image: url("https://assets.dodgethebullet.ca/images/icon-share-link.png");
}

.button-share::after {
  width: 7vw;
  position: absolute; z-index: 1;
  top: -3vw; left: 50%;
  margin-left: -4.5vw;
  padding: 0 1vw;
  color: #000;
  font-size: 0.75vw;
  text-align: center;
  line-height: 3;
  background: #fff;
  border-radius: 0.5rem;
  opacity: 0;
  pointer-events: none;
  content: "Generating Post...";
}
.view.active .button-share.active::after {
  opacity: 1;
  pointer-events: auto;
}
.button-share[data-network="link"].active::after {
  content: "Copied to Clipboard";
}
.button-share::before {
  width: 0; height: 0;
  position: absolute; z-index: 1;
  top: -2.9rem; left: 50%;
  margin-left: -3rem;
  border: 3rem solid transparent;
  border-top-color: #fff;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.view.active .button-share.active::before {
  opacity: 1;
  pointer-events: auto;
}

@media screen and (max-width: 900px) {
  .button-close {
    top: 0.25vh; left: 0.5vw;
    background-position: 50%;
    background-size: 4vw auto;
  }
  .button-share {
    width: 12vw; height: 12vw;
    margin: 0 2vw 0 0;
  }
  .button-share span {
    background-size: 6vw auto;
  }
  .button-share::after {
    width: 8rem;
    top: -4rem;
    margin-left: -5rem;
    padding: 0 1rem;
    font-size: .9rem;
  }
}

@media screen and (max-width: 900px) and (min-width: 480px) {
  .button-share {
    width: 6vw; height: 6vw;
    margin: 0 2vw 0 0;
  }
  .button-share span {
    background-size: 4vw auto;
  }
}

@media screen and (max-width: 600px) and (min-width: 480px) {
  .button-share {
    width: 8vw; height: 8vw;
  }
  .button-share span {
    background-size: 5vw auto;
  }
}

.content {
  background-image: url("https://assets.dodgethebullet.ca/images/background-content.jpg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-attachment: fixed;
  background-size: cover;
}
  .content-wrapper {
    max-width: 80rem;
    margin: 0 auto;
    padding: 4rem 2rem;
  }
  .content h1 {
    font-size: 6rem;
    padding-bottom: 2rem;
  }
  .content h2 {
    font-size: 4rem;
    padding-bottom: 2rem;
  }
  .content p {
    padding-bottom: 2rem;
  }

@media screen and (max-width: 900px) {
  .content-wrapper {
    max-width: 80rem;
    margin: 0 auto;
    padding: 4rem 2rem;
  }
  .content h1 {
    font-size: 4rem;
    padding-bottom: 2rem;
  }
  .content h2 {
    font-size: 3rem;
    padding-bottom: 2rem;
  }
  .content p {
    font-size: 1.4rem;
    padding-bottom: 2rem;
  }
}

.esri-view .esri-view-surface--inset-outline:focus::after {
  display: none;
}
.esri-feature__main-container {
  padding: 2rem 1rem;
}
.esri-view-root .esri-ui {
  inset: 0!important;
}
