html,
body {
  width: 100%; height: 100%;
  min-width: 320px; min-height: 100%;
  font-family: "roboto-regular", Arial, sans-serif;
  font-size: 10px;
  line-height: 1.5;
  color: #fff;
  background: #000;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: 100%;
}

h1,
h2 {
  margin: 0.1em auto;
  font-family: "bebas-regular", Arial, sans-serif;
  font-size: 18em;
  letter-spacing: -0.02em;
  line-height: 0.85;
  text-transform: uppercase;
}

h2 {
  font-size: 14em;
}

p {
  font-size: 2em;
}

a {
  color: #fff;
}

.button {
  box-sizing: border-box;
  display: inline-block;
  margin: 0 0.25em;
  padding: 0 2em;
  color: #fff;
  font-size: 1.75em;
  line-height: 3.5;
  text-decoration: none;
  background: #fc0a1d;
  border-radius: 1.25em;
  transition: color 300ms ease-out, background-color 300ms ease-out;
}
  .button:hover {
    color: #fc0a1d;
    background: #fff;
  }
.button.button-light {
  color: #fc0a1d;
  background: #fff;
}
  .button.button-light:hover {
    color: #fff;
    background: #000;
  }

.app {
  min-width: 320px; height: 100%;
  position: relative;
}

.view {
  width: 100%; height: 100%;
  min-width: 320px;
  position: absolute; z-index: 2;
  top: 0; left: 0;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transform: translate3d(0, 0, 0);
  transition: opacity 0.25s ease-out;
}
.view.active {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.75s ease-out;
}

.unsupported {
  max-width: 52rem;
  margin: 0 auto;
  padding: 6rem 2rem;
  text-align: center;
}
  .unsupported h1 {
    font-size: 4rem;
    line-height: 2;
  }
  .unsupported h2 {
    font-size: 3rem;
    line-height: 2;
  }
  .unsupported p {
    font-size: 1.6rem;
  }
  .unsupported .button {
    margin: 3rem auto;
    line-height: 3;
  }

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 480px) {

}

@media screen and (max-width: 320px) {

}
