.view-index {

}
  .view-index .view-wrapper {
    height: 100%;
    overflow: auto;
  }
  .view-index .view-content {
    position: relative; z-index: 2;
    width: 46vh; height: 82vh;
    margin: 5vh auto 0;
    padding: 2rem;
    font-size: 1.1vh;
  }
  .view-index h1 {

  }
    .view-index h1 span {
      color: #fc0a1d;
    }
  .view-index p {
    padding: 1em 2em 2em 0.5em;
    font-size: 1.75em;
  }
  .view-index .button {
    margin-left: 0.5em;
  }

.logo {
  display: block;
  width: 13rem; height: auto;
  position: absolute; z-index: 2;
  bottom: 4vh; right: 4vh;
}
  .logo img {
    display: block;
    width: 100%; height: auto;
  }

.video-container {
  width: 100vw; min-height: 100vh;
  position: fixed; z-index: 1;
  top: 0; left: 0;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: cover;
}
  .video-container > video {
    display: block;
    position: absolute; z-index: 1;
    top: 50%; right: 0;
    transform: translate3d(0, -50%, 0);
  }

.view-index .legal {
  position: absolute; z-index: 3;
  bottom: 4vh; left: 4vh;
  padding: 0;
  color: #fff;
  font-size: 1.25em;
}
  .view-index .legal a {
    display: inline-block;
    padding: 0 0 0 1em;
  }

@media screen and (max-aspect-ratio: 1920/1080) {
  .video-container > video {
    height: 100%;
  }
}
@media screen and (min-aspect-ratio: 1920/1080) {
  .video-container > video {
    width: 100%;
  }
}

@media screen and (min-width: 1025px) {
  .video-container {
    background-image: url("https://assets.dodgethebullet.ca/images/video-large.jpg");
  }
}
@media screen and (max-width: 1024px) {
  .video-container {
    background-image: url("https://assets.dodgethebullet.ca/images/video-medium.jpg");
  }
}
@media screen and (max-width: 480px) {
  .video-container {
    background-image: url("https://assets.dodgethebullet.ca/images/video-small.jpg");
  }
}

@media screen and (max-width: 900px) {
  .view-index .view-content {
    font-size: 1vh;
    width: 42vh; height: 76vh;
    margin: 2vh 0 0 5vw;
    padding-left: 0;
  }
  .logo {
    width: 8rem;
    bottom: 1rem; right: 1rem;
  }
  .view-index .legal {
    font-size: 1em;
    bottom: 1rem; left: 6vw;
  }
  .view-index .legal span {
    display: block;
  }
  .view-index .legal a {
    padding: 0 1em 0 0;
  }
  .view-index .button {
    margin-left: .35em;
  }
}

@media screen and (max-width: 400px) {
  .view-index .view-content .button {
    height: 12vw;
    padding: 0 1.5em;
    font-size: 4vw;
    line-height: 12vw;
  }
}

@media screen and (max-width: 480px) and (min-height: 720px) {
  .view-index .view-content {
    height: 66vh;
    margin: 8vh auto 0;
    font-size: 0.9vh;
    width: 37vh;
  }
}
