html, body, div, span, main, iframe,
h1, h2, h3, h4, h5, h6, p, a, em, img,
strong, sub, sup, b, u, i, ol, ul, li,
form, label, canvas, footer, header,
menu, nav, section, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, footer, header, main, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

img {
  display: block;
}

template {
  display: none;
}

.clear:before,
.clear:after {
  content: "";
  display: table;
}
.clear:after {
  clear: both;
}
.clear {
  zoom: 1;
}

b, strong {
  font-weight: 700;
}

i, em {
  font-style: italic;
}
